import { autocomplete } from '@algolia/autocomplete-js';
import Fuse from 'fuse.js'
import removeAccents from './remove-accents.js'
class CNAdvancedSearchAutocomplete {
    constructor(el) {
        this.version = advancedSearch.version
        this.sources = []
        this.searchContainer = $(el).find('.js-advanced-search__bar')[0]
        this.options = $(this.searchContainer).data('module-options')
        this.fuseOptions = {
            includeMatches: true,
            threshold: 0,
            ignoreLocation: true,
            keys: [
                ...[
                    "title.rendered",
                ],
                ...this.getTaxonomies()
            ],
            getFn: (obj, path) => {
                var value = Fuse.config.getFn(obj, path);
                if (Array.isArray(value)) {
                  return value.map(el => removeAccents(el));
                }
                return removeAccents(value);
            }
        }
        this.autocomplete = autocomplete(this.getAutocompleteConfig())
        this.searchData = this.getSearchData()

        if(this.options.searchSuggestions.length) {
            this.animateSearchSuggestions(this.options.searchSuggestions)
        }

        return this.autocomplete;
    }

    getAutocompleteConfig() {
        let _this = this

        let config = {
            container: this.searchContainer,
            placeholder: this.options.placeholder,
            openOnFocus: true,
            classNames: {
                detachedSearchButtonPlaceholder: 'js-search-placeholder'
            },
            getSources({ query }) {
                return _this.buildSources(query, _this.options.sourceSelection)
            },
            onStateChange({ state, refresh, setContext }) {
                if(state.isOpen && !_this.searchDataRequested) {
                    _this.searchDataRequested = true

                    // Get dynamic results and populate once retrieved
                    _this.updateSearchData(() => {
                        setContext({ 'searchData': true })
                        refresh()
                    })
                }
            },
            render({ sections, render, html, state }, root) {

                let panelLayoutClass = "aa-PanelLayout aa-Panel--scrollable"

                if(!state.context.searchData) {
                    panelLayoutClass += " is-loading"
                }

                if(state.collections.every((collection) => collection.items.length == 0)) {
                    panelLayoutClass += " is-no-results"
                }

                render(
                  html`<div class="${panelLayoutClass}">
                        ${sections}
                        ${!state.context.searchData ? html`<div class='aa-loadingMessage'><span class='aa-loadingMessageText'>Loading search</span></div>` : ''}
                    </div>`,
                  root
                );
            }
        };

        return config
    }

    getTaxonomies() {
        let taxonomies = []
        Object.keys(advancedSearch.sources).forEach(function(source){
            taxonomies = [...taxonomies, ...advancedSearch.sources[source].taxonomies.map(tax => 'taxonomies.' + tax)]
        })
        return taxonomies
    }

    animateSearchSuggestions(suggestions, i = 0, reverse = false) {
        let suggestion = suggestions[i]
        let letter = reverse ? suggestion.length - 1 : 0

        let typeInterval = setInterval(() => {
            $(this.searchContainer).find('.js-search-placeholder').html(suggestion.substring(0, letter))

            if(letter < suggestion.length && letter >= 0){
                if(reverse) {
                    letter--;
                } else {
                    letter++;
                }
            } else {
                setTimeout(() => {
                    let next = suggestions[i+1] ? i+1 : 0;
                    this.animateSearchSuggestions(suggestions, !reverse ? i : next, !reverse);
                }, (reverse ? 0 : 3000))

                clearInterval(typeInterval)
            }
        }, (reverse ? 40 : 50))
    }

    mergeSearchData(dynamicSource) {
        let trash = dynamicSource.trash || []

        Object.keys(this.searchData).forEach((key) => {

            if(dynamicSource[key] && key != 'timestamp') {

                let searchSourceData = [...this.searchData[key].data],
                    updatedData = dynamicSource[key].data

                // update or add entries as required
                updatedData.forEach((update) => {
                    let existingEntry = searchSourceData.findIndex(originalEntry => originalEntry.id == update.id)

                    if(existingEntry != -1) {
                        searchSourceData[existingEntry] = update
                    } else {
                        searchSourceData.push(update)
                    }
                })

                // remove any entries now in trash
                trash.forEach((id) => {
                    let toDelete = searchSourceData.findIndex((originalEntry) => originalEntry.id == id)
                    if(toDelete != -1) {
                        searchSourceData.splice(toDelete, 1);
                    }
                })

                this.searchData[key].data = searchSourceData
            }
        })

        this.searchData.timestamp = dynamicSource.timestamp
    }

    getSearchData() {
        let searchDataVersion = localStorage.getItem('searchDataVersion')
        let searchData = searchDataVersion == this.version ? localStorage.getItem('searchData') : null

        try {
            searchData = JSON.parse(searchData);

            if(searchData == null) {
                throw "No searchData stored"
            }
        } catch (e) {
            this.autocomplete.setContext({ 'searchData': false })
            return advancedSearch.sources;
        }

        this.autocomplete.setContext({ 'searchData': true })
        return searchData;
    }

    updateSearchData(callback) {
        fetch('/wp-json/advanced-search/v1/data?' + new URLSearchParams({
            timestamp: this.searchData.timestamp ? this.searchData.timestamp : 0
        }))
        .then((response) => response.json())
        .then((response) => {
            this.mergeSearchData(response)
            this.setLocalSearchData()
            callback(response)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    setLocalSearchData() {
        localStorage.setItem('searchData', JSON.stringify(this.searchData))
        localStorage.setItem('searchDataVersion', this.version)
    }

    getHighlightableFields(suggestion) {
        let textFields = [
            'title',
            'name',
            'subtitle',
        ]
        return textFields.filter((field) => field in suggestion)
    }

    highlightSuggestion(suggestion, query) {
        let suggestionTextFields = this.getHighlightableFields(suggestion)

        suggestionTextFields.forEach((field) => {
            let text = suggestion[field].rendered || ''
            let highlight = new RegExp(query, 'gi')

            if(text.match(highlight) && query != ''){
                suggestion[field].highlight = text.replace(highlight, "<mark>$&</mark>")
            } else {
                suggestion[field].highlight = text
            }
        })

        return suggestion
    }

    filterData(data, query) {
        if(query == '') return data

        let fuse = new Fuse(data, this.fuseOptions);
        let search = fuse.search(query)
        return search.map(result => result.item)
    }

    buildSources(query, selection) {
        let _this = this,
            sources = JSON.parse(JSON.stringify(this.searchData)),
            sourceHandles = Object.keys(sources),
            sortCriteria = 'priority'

        if(selection && selection != 'all') {
            sourceHandles = sourceHandles.filter((source) => {
                if(source != selection){
                    delete sources[source]
                    return false
                }
                return true
            })
        }

        if(query == '') {
            sourceHandles = sourceHandles.filter((handle) => {
                return advancedSearch.default_sources.includes(sources[handle].id)
            })
        } else {
            sourceHandles = sourceHandles.filter((handle) => sources[handle].searchable )
        }

        sourceHandles.sort((a, b) => {
            let aScore = sources[a][sortCriteria] || 0
            let bScore = sources[b][sortCriteria] || 0
            return bScore - aScore
        })

        return sourceHandles.map((key) => {

            let source = sources[key]

            return {
                sourceId: source.id,
                getItems({ state }) {
                    let results = []
                    results = _this.filterData(source.data, state.query)
                    return results
                },
                getItemInputValue({ item }) {
                    return _.unescape(item.title.rendered);
                },
                templates: {
                    header({ html }) {
                        return html([wp.template('autocomplete-header')({
                            label: _.escape(source.label)
                        })])
                    },
                    item({ item, html }) {
                        return html([wp.template('autocomplete-suggestion')(_this.highlightSuggestion(item, query))]);
                    },
                    noResults({ html }) {
                        if(_this.searchData != null) {
                            return html([wp.template('autocomplete-results')()])
                        }
                    },
                },
                getItemUrl({ item }) {
                    return item.link
                }
            }
        })
    }
}


$('.js-advanced-search').each(function() {
    let search = new CNAdvancedSearchAutocomplete(this)

    $('[href*="#search"]').on('click', function() {
        search.setIsOpen(true)
    })
})

$(document).on('keydown', '.aa-Input', function(e){
    if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault()
    }
})